<template>
  <div>
    <template v-if="isSetupLoading">
      <v-row class="justify-center ma-0 pa-0">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </template>
    <template v-else-if="!isSetupLoading && !accountingSetup">
      <div class="pt-3 pr-6">
        <hb-empty-state
          :showBtn="true"
          :btnTxt="'Start Accounting Setup'"
          @clicked="startAccountingSetup"
        >
        </hb-empty-state>
      </div>
    </template>
    <template v-else>
      <v-row class="pa-0 ma-0 pr-6">
        <v-col cols class="pa-0 ma-0">
          <MenuTabs
            :menuOptions="menuOptions"
            @setMenuOption="setMenuOption"
            @selectedProperty="setSelectedProperty"
          ></MenuTabs>
        </v-col>
        <v-col cols="3" class="pa-0 ma-0" style="border-bottom: 1px solid #DFE3E8;" v-if="activeTab == 'corporate'">
          <HbSwitch class="float-right mt-2" :label="isToggleAccounting ? 'Enabled' : 'Disabled'" v-model="isToggleAccounting" @change="saveToggleAccounting"/>
        </v-col>
      </v-row>
      <component :is="componentId" :property.sync="property" />
    </template>
  </div>
</template>

<script type="text/babel">
import MenuTabs from "../Common/MenuTabs";
import api from "../../../assets/api.js";
import { mapActions, mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js"
export default {
  name: "AccountingTemplates",
  mixins: [ notificationMixin ],
  data() {
    return {
      menuOptions: [
        {
          key: "corporate",
          label: "Corporate Settings",
        },
        {
          key: "property",
          label: "Property Settings",
        },
      ],
      activeTab: "corporate",
      property: {},
      isSetupLoading: false,
      generalSettingsData: {},
    };
  },
  components: {
    MenuTabs,
    corporate: () => import("./CorporateAccountingTemplates"),
    property: () => import("./PropertyAccountingTemplates"),
  },
  async created() {
    this.isSetupLoading = true;
    await this.fetchAccountingSetup();
    if(this.accountingSetup){
      await this.fetchAccountingSettings();
      await this.setGeneralSettingsObject();
      await this.fetchAccountingTypes();
    }
    this.isSetupLoading = false;
  },
  computed: {
    ...mapGetters({
      accountingSetup: "accountingStore/accountingSetup",
      accountingSettings: "accountingStore/accountingSettings",
      hasPermission: 'authenticationStore/rolePermission',
      accountingTypes: "accountingStore/accountingTypes",
    }),
    componentId() {
      return this.activeTab;
    },
    isToggleAccounting: {
      get () {
        return this.generalSettingsData?.value == "1" ? true : false;
      },
      set (value) {
        this.generalSettingsData.value = value ? "1" : "0";
        return value;
      }
    }
  },
  methods: {
    ...mapActions({
      getAccountingSetup: "accountingStore/getAccountingSetup",
      setAccountingSetup: "accountingStore/setAccountingSetup",
      getAccountingSettings: "accountingStore/getAccountingSettings",
      setAccountingSettings: "accountingStore/setAccountingSettings",
      getAccountingTypes: "accountingStore/getAccountingTypes",
    }),
    async fetchAccountingSettings() {
      try {
        await this.getAccountingSettings();
      } catch (error) {
        this.showMessageNotification({description: error});
      }
    },
    async fetchAccountingTypes(){
      try {
        await this.getAccountingTypes();
      } catch (error) {
        this.showMessageNotification({description: error});
      }
    },
    setGeneralSettingsObject() {
      this.generalSettingsData = JSON.parse(JSON.stringify({name: 'toggleAccounting', value: this.accountingSettings.toggleAccounting}));
    },
    async saveToggleAccounting(val) {
      try {
        await this.setAccountingSettings({
          generalSettings: {[this.generalSettingsData.name] : this.generalSettingsData.value}
        });
        this.showMessageNotification({type: "success", description: "Settings Updated Successfully"});
      } catch (error) {
        this.showMessageNotification({description: error});
      }
    },
    async fetchAccountingSetup() {
      try {
        await this.getAccountingSetup();
      } catch (error) {
        this.showMessageNotification({description: error});
      }
    },
    async startAccountingSetup() {
      this.isSetupLoading = true;
      try {
        await this.setAccountingSetup();
      } catch (error) {
        this.showMessageNotification({description: error});
      }
      this.isSetupLoading = false;
    },
    async setMenuOption(option) {
      this.activeTab = option;
      this.property = {};
    },
    async setSelectedProperty(property) {
      this.property = property;
    },
  },
  watch: {
    async "accountingSettings"(val) {
      await this.setGeneralSettingsObject();
    },
    async "accountingSetup"() {
      await this.fetchAccountingSettings();
      await this.setGeneralSettingsObject();
    }
  }
};
</script>

<style lang="scss" scoped></style>
